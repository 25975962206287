import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.svg";
import logoLightImg from "images/logo-light.svg";
import Cookies from "js-cookie";
import { getAllCategoryNameData } from "services/HomeService";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}
interface OnlineOrderSettings {
  logo?: string;
  orderLeadTime?: number;
  image?: string;
  titleText?: string;
  customTextOne?: string;
  pauseOnlineOrder?: boolean;
  pauseOnlineOrderLabel?: string;
  onlineDeliveryId?: string;
  isDelivery?: boolean;

  // Add other properties if necessary
}
interface ResponseData {
  onlineOrderSettings: OnlineOrderSettings;
  items: any[];
}
const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
}) => {
  const [logo, setLogo] = React.useState<string | null>(null);
  const [merchantName, setMerchantName] = React.useState<string | null>(null);
  useEffect(() => {
    const fetchLogo = async () => {
      const response = await getAllCategoryNameData();
      const obtainedData = (response?.data as ResponseData)?.onlineOrderSettings;
      const onlineData = obtainedData as OnlineOrderSettings;
       if (onlineData?.logo) {
                console.log("onlineData?.logo", onlineData?.logo);
                        Cookies.set("onlineOrderSettingsLogo", onlineData?.logo);
                      }
                      if (onlineData?.orderLeadTime) {
                        Cookies.set(
                          "onlineOrderSettingsLeadTime",
                          onlineData?.orderLeadTime.toString()
                        );
              }
      const merchantname = Cookies.get("merchantname");
      setMerchantName(merchantname as any);
      const logoo = Cookies.get("onlineOrderSettingsLogo") ?? null;
      setLogo(logoo);
    };

    if (!logo) {
      const delay = setTimeout(() => {
        fetchLogo();
      }, 1000); 
      return () => clearTimeout(delay); 
    }
  }, []);
  
console.log(logo,Cookies.get("onlineOrderSettingsLogo"))
  return (
    <Link
      to={`/${merchantName}`}
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      {logo ? (
        <img
        // className={`block max-h-16 sm:max-h-14 ${
          //   logo ? "dark:hidden" : ""
          // } `}
          className="block max-h-16 sm:max-h-14 dark:text-gray-100 rounded-lg"
          src={logo}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
    </Link>
  );
};

export default Logo;
